import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col } from "react-bootstrap"
import readmore from "../../images/topic-1/overview/readmore.png"
import roundLake from "../../images/topic-1/overview/round-lake.jpg"
import step1 from "../../images/topic-1/overview/step-1.jpg"
import step2 from "../../images/topic-1/overview/step-2.jpg"
import step3 from "../../images/topic-1/overview/step-3.jpg"
import step4 from "../../images/topic-1/overview/step-4.jpg"
import BottomNav from "../../components/bottomNav"
import MyBreadcrumb from "../../components/myBreadcrumb"
import Instructions from "../../components/instructions"
import TopHeader from "../../components/topHeader"
import LinkCard from "../../components/linkCard"
import LinkSteps from "../../components/linkSteps"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const ExpGrowthIndex = ({ location }) => {
  const { t } = useTranslation()

  const pageContent = {
    pageTitle: parse(t("Topic1_Overview_pageTitle")),
    breadcrumb: parse(t("Topic1_Overview_breadcrumb")),
    title: parse(t("Topic1_Overview_title")),
    topText: parse(t("Topic1_Overview_topText")),
    middleText: parse(t("Topic1_Overview_middleText")),
    bottomText: parse(t("Topic1_Overview_bottomText")),
    topicsTitle: parse(t("Topic1_Overview_topicsTitle")),
    instructions: parse(t("Topic1_Overview_instructions")),
    linkTexts: [
      parse(t("Topic1_Overview_linkTexts[0]")),
      parse(t("Topic1_Overview_linkTexts[1]")),
      parse(t("Topic1_Overview_linkTexts[2]")),
      parse(t("Topic1_Overview_linkTexts[3]")),
      "/exponentialgrowth/step1",
    ],
    images: [
      {
        src: readmore,
        alt: parse(t("Topic1_Overview_images[0].alt")),
        caption: parse(t("Topic1_Overview_images[0].caption")),
      },
      {
        src: roundLake,
        alt: parse(t("Topic1_Overview_images[1].alt")),
        caption: parse(t("Topic1_Overview_images[1].caption")),
      },
      {
        src: step1,
        alt: parse(t("Topic1_Overview_images[2].alt")),
        caption: parse(t("Topic1_Overview_images[2].caption")),
      },
      {
        src: step2,
        alt: parse(t("Topic1_Overview_images[3].alt")),
        caption: parse(t("Topic1_Overview_images[3].caption")),
      },
      {
        src: step3,
        alt: parse(t("Topic1_Overview_images[4].alt")),
        caption: parse(t("Topic1_Overview_images[4].caption")),
      },
      {
        src: step4,
        alt: parse(t("Topic1_Overview_images[5].alt")),
        caption: parse(t("Topic1_Overview_images[5].caption")),
      },
    ],
  }
  // =========================================================================================================
  return (
    <Layout>
      <SEO title={pageContent.pageTitle} />

      <MyBreadcrumb
        topicTitle=""
        secondTitle=""
        currentTitle={pageContent.breadcrumb}
      />

      <div className="homeContent">
        <Row>
          <Col sm="12" md="12">
            <TopHeader
              title={pageContent.title}
              content={pageContent.topText}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <LinkCard
              link={pageContent.linkTexts[2]}
              image={pageContent.images[0].src}
              alt={pageContent.images[0].alt}
              title={pageContent.linkTexts[0]}
              content={pageContent.linkTexts[1]}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="12">
            {pageContent.middleText}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm="12" md="8">
            {pageContent.bottomText}
          </Col>
          <Col sm="12" md="4">
            {" "}
            <div>
              <img
                src={pageContent.images[1].src}
                alt={pageContent.images[1].alt}
                className="roundMe"
                style={{ maxWidth: `290px`, marginBottom: `1.45rem` }}
              />

              <p className="smText">{pageContent.images[1].caption}</p>
            </div>
          </Col>
        </Row>
        <br />
        <LinkSteps
          title={pageContent.topicsTitle}
          data={[
            {
              id: "step1",
              text: pageContent.images[2].caption,
              image: pageContent.images[2].src,
              alt: pageContent.images[2].alt,
            },
            {
              id: "step2",
              text: pageContent.images[3].caption,
              image: pageContent.images[3].src,
              alt: pageContent.images[3].alt,
            },
            {
              id: "step3",
              text: pageContent.images[4].caption,
              image: pageContent.images[4].src,
              alt: pageContent.images[4].alt,
            },
            {
              id: "step4",
              text: pageContent.images[5].caption,
              image: pageContent.images[5].src,
              alt: pageContent.images[5].alt,
            },
          ]}
        />

        <Row>
          <Col sm="12" md="12">
            <Instructions content={pageContent.instructions} />
          </Col>
        </Row>

        <hr />
        <Row>
          <Col sm="12" md="12" className="alignRight">
            <BottomNav
              prevName={parse(t("Home"))}
              btnLinkPrev="/"
              btnLinkNext={pageContent.linkTexts[4]}
              nextName={parse(t("Next"))}
            />
          </Col>
        </Row>
      </div>
      <br />
    </Layout>
  )
}

export default ExpGrowthIndex
