import React from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const LinkSteps = ({ title, titleColor, data, alt }) => {
  return (
    <div>
      <p style={{ color: titleColor }}>{title}</p>

      <Row className="groupItemsOFF">
        {data.map((value, index) => {
          return (
            <Col sm="6" md="4" lg="3" className="text-center" key={index}>
              <div class="card2">
                <Link to={value.id}>
                  <img
                    src={value.image}
                    alt={alt}
                    style={{ width: "100%", marginBottom: "10px" }}
                    className="round topicImg"
                  />
                </Link>
                <Link to={value.id}>
                  <p className="text-center">{value.text}</p>
                </Link>
              </div>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default LinkSteps
