import React from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const LinkCard = ({ link, image, title, content, alt }) => {
  return (
    <div className="borderMe2 boxShadow centerMe" style={{ maxWidth: "650px" }}>
      <Link to={link} target="_blank">
        <Row>
          <Col sm="2">
            <img src={image} alt={alt} style={{ height: "70px" }} />
          </Col>
          <Col sm="10">
            <p className="blackText">
              <span className="bold">{title}</span> <br />
              {content}
            </p>
          </Col>
        </Row>
      </Link>
    </div>
  )
}

export default LinkCard
